import { render, staticRenderFns } from "./purchase_view.vue?vue&type=template&id=1828f8ea&scoped=true&"
import script from "./purchase_view.vue?vue&type=script&lang=js&"
export * from "./purchase_view.vue?vue&type=script&lang=js&"
import style0 from "./purchase_view.vue?vue&type=style&index=0&id=1828f8ea&scoped=scoped&lang=css&"
import style1 from "@/less/Comm/comm_billing.less?vue&type=style&index=1&lang=less&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1828f8ea",
  null
  
)

export default component.exports